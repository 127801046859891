* {
    box-sizing: border-box;
}

body {
  background-image: url('./components/images/background.jpg');
  margin-bottom: -5rem;
}

.page-container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  font-family: 'Quicksand', sans-serif;
  font-weight: bolder;
}
.content-wrap {
  flex: 1;
}
footer {
  color: white;
  margin-top: 2rem;
}

.info {
  height: 70vh;
  width: auto;
  text-align: center;
}

.info-img{
  display: inline-block;
  height: 100%;
  width: auto;
}

.profile-img {
  width: 30%;
  margin: 0, auto;
}

.video-profile {
  width: 640px;
  height: 360px;
}

@media only screen and (max-width: 576px) {
  .info {
    width: 90vw;
    height: auto;
    text-align: center;
  }
  .info-img{
    display: inline-block;
    width: 100%;
    height: auto;
  }
  .profile-img {
    width: 60%;
    margin: 0, auto;
  }
  .brand {
    margin-left: 0;
  }

  .video-profile {
    width: 320px;
    height: 180px;
  }
}

@media only screen and (min-width: 1400px) {
  .video-profile {
    width: 1280px;
    height: 720px;
  }
}