#js {
  position: relative;
  left: 12rem;
  top:3rem;
}

#html {
  position: relative;
  top:14rem;
  left: 2rem;
}

#psql {
  position: relative;
  right: 17rem;
  top: 11rem;
}

#css {
  position: relative;
  top: 7rem;
  left: 3rem;
}

#ruby {
  position: relative;
  right: 7rem;
  top:3rem
}

#python {
  position: relative;
  top: 20rem;
  left:1rem;
}

#react {
  position: relative;
  left: 17rem;
  top:10rem
}

#ps {
  position: relative;
  left: 9rem;
  top:14rem
}

#illustrator {
  position: relative;
  left:11rem;
  top:2rem;
}

#fm {
  position: relative;
  top: 12rem;
  left: 2rem;
}

#express {
  position: relative;
  top: 14rem;
  right:4rem;
}

#pr {
  position: relative;
  top: 16rem; 
  right: 9rem;
}

#jest {
  position: relative;
  top: 20rem;
  right: 2rem;
}

#nodejs {
  position: relative;
  top: 6rem;
}

#bootstrap {
  position: relative;
  top: 2rem;
  right:2rem
}

#indesign {
  position: relative;
  top: 6rem;
  left:5rem
}